import { makeAutoObservable } from "mobx";
import agent from "../api/agents";
import { store } from './store';
import { Notification } from '../models/notification';
import { HubConnection, HubConnectionBuilder, LogLevel } from '@microsoft/signalr';
import Push from "push.js";
import { history } from '../../index';
import moment from "moment";



export default class NotificationStore {

    hubConnection: HubConnection | null = null;
    notifications: Notification[] = [];
    loadingNotifications = false;
    isNotifcationShow = false;
    take = 10;
    skip = 0;
    unreadCount = 0;
    roleId = "";
    deleteInterval = 0; 
    isAllRendered = false;

    constructor() {
        makeAutoObservable(this);
    }

    pushPermission = () => {
        Push.Permission.request();
    }

    pushNotification(notification: Notification) {
        const url = this.generateUrl(notification);

        Push.create(notification.entityType.name.split(/(?=[A-Z])/).join(" "), {
            body: this.generateContent(notification).replace(/(<([^>]+)>)/ig, ""),
            onClick: () => {
                history.push(url);
            }
        });
    }

    createAppHubConnection = async () => {
        const role = await agent.Roles.getClient();

        this.roleId = role.id;

        this.hubConnection = new HubConnectionBuilder()
            .withUrl(`${process.env.REACT_APP_HUB_URL}`, {
                accessTokenFactory: () => store.authStore.token!,
            })
            .withAutomaticReconnect()
            .configureLogging(LogLevel.None)
            .build();

        this.hubConnection?.start().then(() => {
            if (this.hubConnection)
                agent.Notifications.register(store.userStore.user?.id!, this.roleId, this.hubConnection.connectionId!);

            console.log("App Hub Connected");
        }).catch(e => console.error(e));

        this.hubConnection.on('onNotificationInserted', async (notificationId) => {
            const notification = await agent.Notifications.get(notificationId);
            const item = this.notifications.find(item => item.id === notificationId);

            if (this.notifications.length > 0 && !item) {
                this.notifications = [notification, ...this.notifications];
                this.skip++;
            }

            if (Push.Permission.has())
                this.pushNotification(notification);

            if (!this.isNotifcationShow && !item) {
                this.unreadCount++;
            }
            else {
                this.markRead();
            }
        });

        this.hubConnection.on('onMarkAllAsRead', (isRead) => {
            this.unreadCount = 0;
        });

        this.hubConnection.on('onNotificationDeleted', (id: string) => {
            const item = this.notifications.find(item => item.id === id);

            if (item && this.deleteInterval == 0) {
                this.deleteInterval += 2000;
                this.buffer();
                this.skip--;
            } else {
                this.deleteInterval += 2000;
            }
            this.notifications = this.notifications.filter(n => n.id !== id);
        });
    }
    renderNewNotif = async () => {
        const { notifications, total } = await agent.Notifications.filter(store.userStore.user?.id!, this.roleId, this.notifications.length, 10 - this.notifications.length);
        this.notifications = [...this.notifications, ...notifications];

        if (total == this.notifications.length) {
            this.isAllRendered = true;
        } else {
            this.isAllRendered = false;
        }
       
    }
     buffer = () => {
        setTimeout(() => {

            if (this.deleteInterval > 0) {
                this.deleteInterval -= 2000;
                this.buffer();
            } else {
                this.renderNewNotif();
            };
        },this.deleteInterval);
    };


    disconnectAppHubConnection = () => {
        //agent.Notifications.unregister(store.userStore.user?.id!, this.roleId, this.hubConnection?.connectionId!);
        this.hubConnection?.stop()
        this.hubConnection = null;
    }

    filter = async () => {
        this.loadingNotifications = true;
        const { notifications, total } = await agent.Notifications.filter(store.userStore.user?.id!, this.roleId, this.skip, this.take);
        this.loadingNotifications = false;
        this.notifications = [...this.notifications, ...notifications];
        this.skip += this.take;
     
        if (total == this.notifications.length) {
            this.isAllRendered = true;
        } else {
            this.isAllRendered = false;
        }
    }

    getUnreadCount = async () => {
        this.unreadCount = await agent.Notifications.getUnreadCount(store.userStore.user?.id!, this.roleId);
    }

    markRead = () => {
        this.unreadCount = 0;
        agent.Notifications.markRead(store.userStore.user?.id!, this.roleId);
    }

    markOpened = (notificationId: string) => {
        agent.Notifications.markOpened(notificationId);
        this.updateOpened(notificationId);
    }

    toggleNotificationBox = () => {
        this.isNotifcationShow = !this.isNotifcationShow;
    }

    showNotificationBox = () => {
        this.isNotifcationShow = true;
    }

    hideNotificationBox = () => {
        this.isNotifcationShow = false;
    }

    updateOpened(notificationId) {
        this.notifications = this.notifications.map(n => {
            if (n.id === notificationId) n.opened = true;

            return n;
        })
    }

    generateUrl = (notification: Notification) => {
        let url = `/requests/`;

        switch (notification.entityType.name) {
            case "LeaveRequest":
                url += "leave-requests";
                break;
            case "Overtime":
                url += "overtime-requests";
                break;
            case "Undertime":
                url += "undertime-requests";
                break;
            case "TimeAdjustment":
                url += "time-adjustments";
                break;
            default:
        }

        url += `?search=${notification.content.user.firstName} ${notification.content.user.lastName}`;

        return url;
    }

    generateContent = (notification: Notification) => {

        let content = notification.notificationTemplate.content;

        let employeeName = `${notification.content.user.firstName} ${notification.content.user.lastName}`;

        content = content.replace(/{EmployeeName}/g, `<b>${employeeName}</b>`);
        content = content.replace(/{Actor}/g, `<b>${notification.actor.name}</b>` );

        switch (notification.entityType.name) {
            case "Overtime":
                const overtimefrom = this.formatToTimeZoneShortDate((notification.content.from).toString());
                const overtimeto = this.formatToTimeZoneShortDate((notification.content.to).toString());

                if (overtimefrom === overtimeto)
                    content = content.replace(/{Date}/g, overtimefrom);
                else
                    content = content.replace(/{Date}/g, `${overtimefrom} to ${overtimeto}`);
                break;
            case "Undertime":

                const undertimefrom = this.formatToTimeZoneShortDate((notification.content.from).toString());
                const undertimeto = this.formatToTimeZoneShortDate((notification.content.to).toString());

                if (undertimefrom === undertimeto)
                    content = content.replace(/{Date}/g, undertimefrom);
                else
                    content = content.replace(/{Date}/g, `${undertimefrom} to ${undertimeto}`);
                break;
            case "TimeAdjustment":
                const dateTime = this.formatToTimeZoneShortDate(notification.content.dateTime.toString());

                content = content.replace(/{Date}/g, dateTime);
                content = content.replace(/{AdjustmentType}/g, notification.content.timeAdjustmentType.type);
                break;
            case "TimeRecordRequest":
                break;
            default:
                const from = this.formatToTimeZoneShortDate(notification.content.from.toString());
                const to = this.formatToTimeZoneShortDate(notification.content.to.toString());

                if (from === to)
                    content = content.replace(/{Date}/g, from);
                else
                    content = content.replace(/{Date}/g, `${from} to ${to}`);

                break;
        }

        return content;
    }
    reset = () => {
        this.notifications = [];
        this.isNotifcationShow = false;
        this.unreadCount = 0;
        this.take = 10;
        this.skip = 0;
    }

    formatLocalDateTime = (date: string) => {
        let output = '';

        if (date) {
            let utcDate = moment.utc(date);
            let localDate = utcDate.local();

            output = localDate.format('MM/DD/YYYY')
        }

        return output;
    };

    formatToTimeZoneShortDate = (date: string) => {
        const timeZone = store.userStore.user?.timeZone;

        if (!timeZone) {
            return date = new Date(date).toLocaleDateString();
        }

        return date ? moment.utc(date).tz(timeZone).format('MM/DD/YYYY') : '';
    };

    onPageUnmounted = async () => {
        this.notifications = [];
        this.isNotifcationShow = false;
        this.take = 10;
        this.skip = 0;
        await this.filter();
    }

    formatDate = (val) => {
        let output;
        const timeZone = store.userStore.user?.timeZone;

        if (!timeZone) {
            let date = new Date(val)
            let today = new Date();
            let utcDate = moment.utc(val);
            let localDate = utcDate.local();

            if (today.toLocaleDateString() == date.toLocaleDateString()) {
                output = moment.utc(val).fromNow();
            } else if (today.getFullYear() > date.getFullYear()) {
                output = localDate.format('MMM DD, YYYY');
            } else {
                output = `${localDate.format('MMM DD')} at ${localDate.format('hh:mm A')}`
            }
            return output;
        }

        let timeZoneDate = moment.utc(val).tz(timeZone);
        let timeZoneDateNow = moment.tz(timeZone);

        if (timeZoneDateNow.isSame(timeZoneDate, 'day')) {
            output = moment.utc(val).fromNow();
        } else if (timeZoneDateNow.year() > timeZoneDate.year()) {
            output = timeZoneDate.format('MMM DD, YYYY');
        } else {
            output = `${timeZoneDate.format('MMM DD')} at ${timeZoneDate.format('hh:mm A')}`
        }

        return output;
    }
}